<template>
  <div class="table-view">
    <table-header
      :items="headers"
      :headersOrder="headersOrder"
      :choices="choices"
      @onOrderChange="onColsOrderChange"
      @onFilterChange="onFilterChange"
      @toggleSort="toggleSort"
      @onHeaderDelete="onHeaderDelete"
    ></table-header>
    <v-virtual-scroll :items="items" height="600" :item-height="50">
      <template v-slot:default="{ item }">
        <div class="table-view__row">
          <cell-factory
            class="table-view__col"
            :class="getRowSelected(item) ? 'yellow accent-1' : ''"
            v-for="header in headersOrder"
            @onChange="onCellValueChange"
            @dblclick.native="onSelectRow(item)"
            :key="headers[header].slug"
            :data="item"
            :maxWidth="headers[header].max_width"
            :slug="headers[header].slug"
            :config="headers[header].cell_config"
          />
        </div>
      </template>
    </v-virtual-scroll>

    <transition-group name="list" class="table-view__list">
      <!-- <v-layout> -->

      <!-- <v-flex v-for="header in headers" :key="header.slug" class="table-view-item table-view__col">
        <cell-factory
          v-for="item in items"
          :key="item.uuid"
          :data="item"
          :slug="header.slug"
          :config="header.cell_config"
        />
      </v-flex> -->
      <!-- </v-layout> -->
    </transition-group>
  </div>
</template>

<script>
import CellFactory from "./Cells/CellFactory.vue";
import TableHeader from "./TableHeader.vue";
export default {
  components: { TableHeader, CellFactory },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    headers: {
      type: Object,
      default: () => {},
    },
    choices: {
      type: Object,
      default: () => {},
    },
    headersOrder: {
      type: Array,
      default: () => [],
    },
    selectedRows: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    onColsOrderChange(oldIndex, newIndex) {
      this.$emit("onColsOrderChange", oldIndex, newIndex);
    },
    onFilterChange(value, selected, slug) {
      this.$emit("onFilterChange", value, selected, slug);
    },
    toggleSort(slug) {
      this.$emit("toggleSort", slug);
    },
    onHeaderDelete(index) {
      this.$emit("onHeaderDelete", index);
    },
    onCellValueChange(uuid, slug, val) {
      this.$emit("onCellValueChange", uuid, slug, val);
    },
    onSelectRow(item) {
      this.$emit("onSelectRow", item);
    },
    getRowSelected(item) {
      return this.selectedRows.includes(item.uuid);
    },
  },
};
</script>

<style lang="scss" scoped></style>
