<template>
  <!-- <thead>
    <tr>
      <th v-for="item in items" :key="item.toString()">{{ item.title }}</th>
    </tr>
  </thead> -->
  <div>
    <draggable
      group="headers"
      id="header-trash"
      class="table-view__trash"
      v-if="dragging"
    >
      <v-card class="rounded-circle pa-10" width="100%" height="100%">
        <v-layout align-start justify-start fill-height>
          <v-icon size="50" color="red">mdi-delete</v-icon>
        </v-layout>
      </v-card>
    </draggable>
    <draggable
      :value="headersOrder"
      @end="onDragEnd"
      @start="onDragStart"
      group="headers"
      :removeCloneOnHide="true"
    >
      <!-- <transition-group tag="tr" name="list">
      <th v-for="item in items" class="flip-list" :key="item.slug">{{ item.title }}</th>
    </transition-group> -->

      <transition-group class="table-view__header">
        <table-title
          v-for="header in headersOrder"
          :key="header"
          v-bind="items[header]"
          :slug="header"
          :filterChoices="choices[header]"
          :activeDrag="draggedItem == header"
          :dragging="dragging"
          :maxWidth="items[header] ? items[header].max_width : null"
          @onFilterChange="onFilterChange"
          @toggleSort="toggleSort"
        >
        </table-title>
      </transition-group>
    </draggable>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import TableTitle from "./TableTitle.vue";
export default {
  components: {
    draggable,
    TableTitle,
  },
  props: ["items", "choices", "headersOrder"],
  data() {
    return {
      draggedItem: null,
      dragging: false,
    };
  },
  methods: {
    onDragEnd(event) {
      if (event.to.id === "header-trash") {
        this.headerToTrash(event.oldIndex);
      } else {
        this.$emit("onOrderChange", event.oldIndex, event.newIndex);
      }

      this.dragging = false;
      this.draggedItem = null;
    },
    onFilterChange(value, selected, slug) {
      this.$emit("onFilterChange", value, selected, slug);
    },
    toggleSort(slug) {
      this.$emit("toggleSort", slug);
    },
    onDragStart(e) {
      let index = e.oldDraggableIndex;
      this.dragging = true;
      this.draggedItem = this.headersOrder[index];
    },
    headerToTrash(index) {
      this.$emit("onHeaderDelete", index);
    },
  },
};
</script>

<style lang="scss" scoped></style>
