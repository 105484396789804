import _ from "lodash";
import api from "../../api";
import { getField, updateField } from "vuex-map-fields";

import instance from "./forms/symbol";

let initialState = {
  query: {
    limit: 5,
  },
  loading: false,
  data: [],
  search: null,
};

const state = () => ({
  ...initialState,
});

const getters = {
  getField,
  formated(state) {
    let users = { ...state.users };

    return users;
  },

  filteredData(state, __, rootState) {
    let data = _.cloneDeep(state.data);
    let search = _.cloneDeep(state.search);
    let sort = _.cloneDeep(rootState["table-settings"].sort);
    let filters = _.cloneDeep(rootState["table-settings"].filters);
    let filtered = [];

    if (search !== null && search.length > 0) {
      let query = search.toLowerCase();
      data = data.filter((symbol) => {
        for (const key in symbol) {
          if (
            typeof symbol[key] === "string" &&
            symbol[key].toLowerCase().indexOf(query) != -1
          ) {
            return true;
          }
        }
        return false;
      });
    }

    if (Object.keys(filters).length == 0) {
      filtered = data;
    } else {
      filtered = data.filter((item) => {
        let itemFits = true;
        for (const key in filters) {
          if (
            !item[key] ||
            !filters[key].data.find((filterItem) => {
              return filterItem.value === item[key];
            })
          ) {
            itemFits = false;
          }
        }
        return itemFits;
        // !filters[key].data.find((filterItem) => {
        //   return filterItem.value === item[key];
        // }
        // for (const key in item) {
        //   if (
        //     filters[key] &&
        //     filters[key].data.find((filterItem) => {
        //       return filterItem.value === item[key];
        //     })
        //   ) {
        //     return item;
        //   }
        // }
      });
    }

    if (Object.keys(sort).length > 0) {
      filtered = _.sortBy(filtered, [sort.slug]);
      if (sort.dir === "desc") filtered = filtered.reverse();
    }

    return filtered;
  },
};

const actions = {
  fetch(context) {
    let promise;

    promise = api.symbol.fetch(context.state.query);

    context.commit("setLoading", true);

    return promise
      .then((response) => {
        let { data, meta } = response.data;
        let formattedData = data.map((item) => {
          item.sector = _.get(item, "sector.name", null);
          item.industry = _.get(item, "industry.name", null);
          item.stock_exchange = _.get(item, "stock_exchange.name", null);
          item.category = _.get(item, "category.name", null);
          return item;
        });
        context.commit("applyFetched", formattedData);
        let choices = meta.choices;
        context.dispatch("table-settings/setChoices", choices, { root: true });
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        context.commit("setLoading", false);
      });
  },
};

const mutations = {
  updateField,
  setLoading(state, status) {
    state.loading = status;
  },
  applyFetched(state, symbols) {
    state.data = _.cloneDeep(symbols);
  },
};
const modules = {
  instance: instance,
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
  modules,
};
