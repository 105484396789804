import _ from "lodash";
import api from "../../../api";
import { getField, updateField } from "vuex-map-fields";

let initialState = {
  symbol: null,
};

const state = () => ({
  ...initialState,
});

const getters = {
  getField,
};

const actions = {
  store(context) {
    let symbol = _.cloneDeep(context.state.symbol);

    let promise = api.symbol.update(symbol.uuid, symbol);
    context.commit("setLoading", true);

    return promise
      .then((response) => {
        context.commit("setLoading", false);
      })
      .catch((e) => {
        context.commit("setLoading", false);
        console.log(e);
      });
  },
};

const mutations = {
  updateField,

  setLoading(state, status) {
    state.loading = status;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
