import store from "../../store";
import _ from "lodash";
import api from "../../api";
import router from "../../router";
import axios from "axios";
export default {
  type: "response",
  error: (error) => {
    let status = _.get(error, "response.status", null);
    let messages = _.get(error, "response.data.errors", null);
    let data = _.get(error, "response.data", {});

    // store.dispatch('message/clear');
    // let auth = store.state.auth.authenticated;

    // if (status == 401 && auth == true) {
    //     if(!localStorage.getItem('refresh_token')) return;
    //     store.dispatch('auth/refreshing');
    //     router.replace({name: 'login.index'})
    //     let login_instance = {
    //         "grant_type": 'refresh_token',
    //         "client_id": process.env.VUE_APP_CLIENT_ID,
    //         "refresh_token": localStorage.getItem('refresh_token'),
    //     }
    //     localStorage.removeItem('refresh_token')

    //     return api.auth
    //         .login(login_instance)
    //         .then(response => {
    //             const config = error.config;
    //             store.dispatch('auth/refreshToken', response.data);
    //             config.headers[
    //                 'Authorization'
    //             ] = `Bearer ${response.data.access_token}`;
    //             router.replace({name: 'home'})
    //             return new Promise((resolve, reject) => {
    //                 return axios
    //                 .request(config)
    //                 .then(response => {
    //                     resolve(response);
    //                 })
    //                 .catch(error => {
    //                     reject(error);
    //                 });
    //             });
    //         })
    //         .catch(error => {
    //             store.dispatch('auth/logout');
    //             store.dispatch('message/put', {
    //                 type: 'error',
    //                 text: 'Your session expired',
    //             });

    //             Promise.reject(error);
    //     });
    // }
    // if(status == 500 || status == null){
    //     store.dispatch('message/put', {
    //         type: 'error',
    //         text: 'Looks like we have problem on our server.',
    //     });
    //     return;
    // }
    // if(status == 403 ){
    //     store.dispatch('message/put', {
    //         type: 'error',
    //         text: 'Access forbidden',
    //     });
    //     return;
    // }
    // if(status == 404 ){
    //     store.dispatch('message/put', {
    //         type: 'error',
    //         text: 'Resource Not found',
    //     });
    //     return;
    // }
    // if(status == 503){
    //     store.dispatch('message/put', {
    //         type: 'error',
    //         text: 'Service Unavailable',
    //     });
    //     return;
    // }
    // store.dispatch('message/put', {
    //     type: 'error',
    //     text: data.message || data.exception || data.error_description || _.get(messages[0], 'title', ''),
    //   });
    return Promise.reject(error);
  },
};
