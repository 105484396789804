import http from "../http";
import services from "../http/services";

const api = http(services.api);

export default {
  symbol: {
    fetch() {
      return api.get("/symbol");
    },
    update(uuid, data) {
      return api.put(`/symbol/${uuid}`, data);
    },
  },
};
