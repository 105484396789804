<template>
  <div
    class="flip-list table-view__title primary white--text"
    :style="customStyles"
    :class="classList"
    v-if="!hidden"
  >
    <span class="mr-auto">{{ title }} </span>
    <v-btn
      small
      icon
      color="white"
      v-if="sortEnabled"
      @click="onSortClick"
      class="table-view__sort"
      :class="sort === null ? '' : 'table-view__sort-active'"
    >
      <v-icon>{{ sort === "asc" ? "mdi-arrow-down" : "mdi-arrow-up" }}</v-icon>
    </v-btn>
    <v-menu offset-y :close-on-content-click="false" max-height="200">
      <template v-slot:activator="{ on, attrs }" v-if="filterEnabled">
        <v-btn color="white" small icon v-on="on">
          <v-icon>mdi-filter-variant</v-icon></v-btn
        >
      </template>
      <v-list>
        <v-list-item dense v-for="(item, index) in filterChoices" :key="index">
          <v-checkbox
            readonly
            :input-value="item.selected"
            hide-details
            dense
            :label="item.title"
            @click="onFilterChange(item)"
          ></v-checkbox>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  props: [
    "title",
    "sort",
    "filter",
    "slug",
    "filterEnabled",
    "sortEnabled",
    "dragging",
    "activeDrag",
    "filterChoices",
    "hidden",
    "maxWidth",
  ],
  computed: {
    classList() {
      return {
        "table-view__title-dragging grey": this.dragging && this.activeDrag,
        "table-view__title-shade": this.dragging && !this.activeDrag,
      };
    },
    customStyles() {
      return {
        "flex-basis": this.maxWidth ? this.maxWidth : "1",
        "flex-grow": this.maxWidth ? "0" : "1",
      };
    },
  },
  methods: {
    onSortClick(e) {
      e.stopPropagation();
      this.$emit("toggleSort", this.slug);
    },
    onFilterChange(item) {
      this.$emit("onFilterChange", item.value, !item.selected, this.slug);
    },
  },
};
</script>

<style lang="scss" scoped></style>
