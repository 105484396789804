<template>
  <div>
    <v-expansion-panels accordion>
      <v-expansion-panel
        v-for="(filter, key) in filterChoices"
        :key="key"
        v-show="filter.length > 0"
      >
        <v-expansion-panel-header>
          {{ headers[key].title }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-chip-group column>
            <v-chip
              @click="onFilterChange(item, key)"
              small
              :color="item.selected ? 'primary' : 'light'"
              v-for="item in filter"
              :key="item.value"
            >
              {{ item.title }}
            </v-chip>
          </v-chip-group>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <!-- <div v-for="(filter, key) in filterChoices" :key="key">
      <div v-show="filter.length > 0">
        <b>{{ headers[key].title }}:</b>
        <v-chip-group column>
          <v-chip
            @click="onFilterChange(item, key)"
            small
            :color="item.selected ? 'primary' : 'light'"
            v-for="item in filter"
            :key="item.value"
          >
            {{ item.title }}
          </v-chip>
        </v-chip-group>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  props: {
    filters: {
      type: Object,
    },
    filterChoices: {
      type: Object,
    },
    headers: {
      type: Object,
    },
  },
  methods: {
    onFilterChange(item, slug) {
      let { value, selected } = item;
      this.$emit("onFilterChange", value, !selected, slug);
    },
  },
};
</script>

<style lang="scss" scoped></style>
