import _ from "lodash";
import api from "../../api";
import { getField, updateField } from "vuex-map-fields";

//import instance from './forms/symbol';

let initialState = {
  settings: {
    headers: {
      category: {
        slug: "category",
        title: "Category",
        cell_config: {},
        filterEnabled: true,
        sort: null,
        sortEnabled: true,
        //     max_width: '90px',
      },
      name: {
        slug: "name",
        title: "Ticker",
        cell_config: {},
        filterEnabled: false,
        sort: null,
        sortEnabled: true,
        //     max_width: '90px',
      },
      isin: {
        slug: "isin",
        title: "ISIN",
        cell_config: {},
        filterEnabled: false,
        sortEnabled: true,
        sort: null,
      },
      description: {
        slug: "description",
        title: "Name",
        cell_config: {},
        filterEnabled: false,
        sortEnabled: true,
        sort: null,
      },
      currency_base: {
        slug: "currency_base",
        title: "Currency Base",
        filterEnabled: false,
        sortEnabled: true,
        sort: null,
        cell_config: {},
        //    max_width: '150px',
      },
      currency_profit: {
        slug: "currency_profit",
        title: "Currency Profit",
        sortEnabled: true,
        cell_config: {},
        sort: null,
        //    max_width: '150px',
      },
      sector: {
        slug: "sector",
        title: "Sector",
        cell_config: {
          // get: (item) => (item.sector ? item.sector.name : null),
          // getValue: (item) => (item.sector ? item.sector.uuid : null),
        },
        filterEnabled: true,
        sortEnabled: true,
        sort: null,
      },
      industry: {
        slug: "industry",
        title: "Industry",
        filterEnabled: true,
        sortEnabled: true,
        cell_config: {
          // get: (item) => (item.industry ? item.industry.name : null),
          // getValue: (item) => (item.industry ? item.industry.uuid : null),
        },
        sort: null,
      },
      stock_exchange: {
        slug: "stock_exchange",
        title: "Stock",
        filterEnabled: true,
        sortEnabled: true,
        //  max_width: '110px',
        cell_config: {
          // get: (item) => (item.stock_exchange ? item.stock_exchange.name : null),
          // getValue: (item) => (item.stock_exchange ? item.stock_exchange.uuid : null),
        },
        sort: null,
      },
      notes: {
        slug: "notes",
        title: "Notes",
        filterEnabled: false,
        sortEnabled: false,
        max_width: "310px",
        cell_config: {
          type: "text-field",
          // get: (item) => (item.stock_exchange ? item.stock_exchange.name : null),
          // getValue: (item) => (item.stock_exchange ? item.stock_exchange.uuid : null),
        },
        sort: null,
      },
    },

    headers_order: [
      "category",
      "name",
      "isin",
      "description",
      "currency_base",
      "currency_profit",
      "sector",
      "industry",
      "stock_exchange",
      "notes",
    ],
  },
  filters: {},
  choices: {},
  sort: {},
  selectedRows: [],
};

const state = () => ({
  ...initialState,
});

const getters = {
  getField,
};

const actions = {
  setChoices(context, choices) {
    let headers = context.state.settings.headers;
    let formattedChoices = {};
    for (const key in headers) {
      if (choices[key]) {
        formattedChoices[key] = choices[key].map((choice) => ({
          title: choice.name,
          value: choice.name,
          selected: false,
        }));
      }
    }

    context.commit("setChoices", _.cloneDeep(formattedChoices));
  },
};

const mutations = {
  updateField,
  setChoices(state, choices) {
    state.choices = choices;
  },
};
// const modules = {
//     instance: instance,
// };
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
  //  modules
};
