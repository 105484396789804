<template>
  <!-- <v-container fluid> -->
  <div>
    <toolbar
      @onFilterChange="onFilterChange"
      @onColumnsShowChange="onColumnsShowChange"
      @onSearchInput="onSearchInput"
      :searchValue="search"
      :filters="{ ...filters }"
      :headers="headers"
      :filterChoices="choices"
      :headersOrder="headers_order"
    >
    </toolbar>
    <v-container fluid>
      <v-card>
        <table-view
          :items="filteredSymbols"
          :headers="headers"
          :choices="choices"
          :headersOrder="headers_order"
          :selectedRows="selectedRows"
          @onColsOrderChange="onColsOrderChange"
          @onFilterChange="onFilterChange"
          @toggleSort="toggleSort"
          @onHeaderDelete="onHeaderDelete"
          @onCellValueChange="onCellValueChange"
          @onSelectRow="onSelectRow"
        >
        </table-view>
      </v-card>
    </v-container>
  </div>
  <!-- </v-container> -->
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { mapFields } from "vuex-map-fields";
import TableView from "../components/TableView/TableView.vue";
import Toolbar from "../components/home/Toolbar.vue";
import _ from "lodash";
// {
// 	"0": {
// 		"uuid": "549bc409-07d0-465e-8e97-0ac29e80e119",
// 		"description": "Australian Dollar vs Canadian Dollar",
// 		"name": "AUDCAD",
// 		"isin": null,
// 		"currency_base": "AUD",
// 		"currency_profit": "CAD",
// 		"trade_stops_level": 0,
// 		"trade_freeze_level": 0,
// 		"volume_max": "100",
// 		"volume_min": "0.1",
// 		"volume_step": "0.01",
// 		"price_change": "0",
// 		"sector_uuid": "1788d361-a10a-40e5-9937-389e956d6f73",
// 		"industry_uuid": null,
// 		"stock_exchange_uuid": null,
// 		"created_at": null,
// 		"updated_at": null,
// 		"sector": {
// 			"uuid": "1788d361-a10a-40e5-9937-389e956d6f73",
// 			"name": "Currency",
// 			"created_at": "2021-04-21 13:14:02",
// 			"updated_at": "2021-04-21 13:14:02"
// 		},
// 		"industry": null,
// 		"stock_exchange": null
// 	}
// }
function arrayMove(arr, oldIndex, newIndex) {
  arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
}
export default {
  components: {
    TableView,
    Toolbar,
  },
  computed: {
    ...mapFields("symbol", {
      symbols: "data",
      search: "search",
    }),
    ...mapFields("symbol/instance", {
      symbolInstance: "symbol",
    }),
    ...mapFields("table-settings", {
      headers: "settings.headers",
      filters: "filters",
      choices: "choices",
      sort: "sort",
      headers_order: "settings.headers_order",
      selectedRows: "selectedRows",
    }),
    ...mapGetters("symbol", {
      filteredSymbols: "filteredData",
    }),
  },
  methods: {
    ...mapActions("symbol", {
      fetch: "fetch",
    }),
    ...mapActions("symbol/instance", {
      updateSymbol: "store",
    }),
    onColsOrderChange(oldIndex, newIndex) {
      arrayMove(this.headers_order, oldIndex, newIndex);
    },
    onFilterChange(value, selected, slug) {
      this.choices[slug].map((item) => {
        if (item.value == value) {
          item.selected = selected;
          this.filters = { ...this.filters };
          this.filters[slug] = {
            title: item.title,
            data: this.choices[slug].filter(
              (filterItem) => filterItem.selected === true
            ),
          };
          if (this.filters[slug].data.length == 0) {
            _.unset(this.filters, slug);
          }
        }
        return item;
      });
    },
    toggleSort(slug) {
      for (const key in this.headers) {
        let item = this.headers[key];
        if (item.slug == slug) {
          item.sort = item.sort == "asc" ? "desc" : "asc";
          this.sort = {
            slug: slug,
            dir: item.sort,
          };
        } else {
          item.sort = null;
        }
        // return item;
      }
      // this.headers = this.headers.map((item) => {

      // });
    },
    onColumnsShowChange(slug) {
      if (this.headers_order.includes(slug)) {
        this.headers_order = this.headers_order.filter((item) => item !== slug);
      } else {
        this.headers_order.push(slug);
      }
    },
    onHeaderDelete(index) {
      let rem = this.headers_order.splice(index, 1);
    },
    onCellValueChange(uuid, slug, val) {
      let itemToUpd = null;

      this.symbols = this.symbols.map((item) => {
        if (item.uuid == uuid) {
          item[slug] = val;
          itemToUpd = item;
        }
        return item;
      });

      if (itemToUpd) {
        this.symbolInstance = itemToUpd;
        this.updateSymbol();
      }
    },
    onSelectRow(row) {
      if (this.selectedRows.includes(row.uuid)) {
        console.log("s");
        this.selectedRows = this.selectedRows.filter(
          (item) => item !== row.uuid
        );
      } else {
        this.selectedRows.push(row.uuid);
      }
    },
    onSearchInput(val) {
      this.search = val;
    },
  },
  created() {
    this.fetch();
  },
};
</script>

<style lang="scss" scoped></style>
