const routes = [
  {
    path: "/",
    name: "home",
    component: () => import(/* webpackChunkName: "home" */ "../views/Home.vue"),
    meta: {
      auth: true,
      title: "Home",
    },
  },
];

export default routes;
