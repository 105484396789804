import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes";
import store from "../store";
import _ from "lodash";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(function (to, from, next) {
  // const is_authenticated = store.state.auth.authenticated;
  // const requires_auth = to.matched.some((route) => _.get(route, 'meta.auth', false));

  // //Check if the route requires authentication and user is not logged in

  // if (requires_auth == true && is_authenticated == false) {
  //   return next({ name: 'login.index' });
  // }

  // if (is_authenticated == true && to.name == 'login.index') {
  //   return next({ name: 'home' });
  // }

  return next();
});

export default router;
