import _ from "lodash";

export default {
  type: "request",
  handler: (config) => {
    if (config.method != "get" && config.method != "post") {
      if (config.data instanceof FormData) {
        config.data.append("_method", _.toUpper(config.method));
        config.method = "post";
      }
    }

    return config;
  },
};
