import store from "../../store";
import _ from "lodash";

export default {
  type: "request",
  handler: (config) => {
    let token = _.get(store.state, "auth.token");
    const { baseURL } = config;

    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }

    return config;
  },
};
