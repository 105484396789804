import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import vuetify from "./plugins/vuetify";
import "./scss/main.scss";
import moment from "moment";

Vue.prototype.$moment = moment;

Vue.config.productionTip = false;
new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
  mounted() {
    // if (!this.$store.state.auth.authenticated) {
    //   this.$router.replace({ name: 'login.index' });
    // }
    // this.$store.dispatch('auth/iam');
  },
}).$mount("#app");
