<template>
  <!-- <v-card class="mb-3 pa-2 d-flex">
    <v-row justify="end">
      <v-col cols="3" class="d-flex justify-end">
        <v-btn depressed color="primary" class="mr-3" @click="showFilters = !showFilters">
          Filters
          <v-icon right>
            {{ !showFilters ? 'mdi-arrow-down' : 'mdi-arrow-up' }}
          </v-icon>
        </v-btn>
        <v-menu left offset-x :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn depressed color="primary" v-bind="attrs" v-on="on"> Columns </v-btn>
          </template>

          <v-list>
            <v-list-item v-for="item in headers" :key="item.slug">
              <v-switch
                hide-details
                :label="item.title"
                readonly
                :input-value="headersOrder.includes(item.slug)"
                @click="$emit('onColumnsShowChange', item.slug)"
              ></v-switch>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
      <v-col cols="12">
        <v-expand-transition>
          <filters-bar
            @onFilterChange="onFilterChange"
            :filters="filters"
            :headers="headers"
            :filterChoices="filterChoices"
            v-show="showFilters"
          ></filters-bar>
        </v-expand-transition>
      </v-col>
    </v-row>
  </v-card> -->
  <div>
    <v-navigation-drawer v-model="showFilters" absolute temporary width="500">
      <filters-bar
        @onFilterChange="onFilterChange"
        :filters="filters"
        :headers="headers"
        :filterChoices="filterChoices"
        v-show="showFilters"
      ></filters-bar>
    </v-navigation-drawer>

    <v-app-bar color="primary" dense dark>
      <v-btn icon class="mr-3" @click="showFilters = !showFilters">
        <v-icon> mdi-filter </v-icon>
      </v-btn>
      <v-menu left offset-x :close-on-content-click="false">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-format-list-checkbox</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item v-for="item in headers" :key="item.slug">
            <v-switch
              hide-details
              :label="item.title"
              readonly
              :input-value="headersOrder.includes(item.slug)"
              @click="$emit('onColumnsShowChange', item.slug)"
            ></v-switch>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-spacer></v-spacer>
      <v-text-field
        @input="onSearchInput"
        :value="searchValue"
        hide-details
        label="Search"
      ></v-text-field>
      <v-spacer></v-spacer>
    </v-app-bar>
  </div>
</template>

<script>
import FiltersBar from "./FiltersBar.vue";
export default {
  components: { FiltersBar },
  props: {
    filters: {
      type: Object,
    },
    filterChoices: {
      type: Object,
    },
    headersOrder: {
      type: Array,
    },
    headers: {
      type: Object,
    },
    searchValue: {
      type: String,
    },
  },
  data() {
    return {
      showFilters: false,
    };
  },
  methods: {
    onFilterChange(value, selected, slug) {
      this.$emit("onFilterChange", value, selected, slug);
    },
    onColumnsShowChange() {},
    onSearchInput(val) {
      this.$emit("onSearchInput", val);
    },
  },
};
</script>

<style lang="scss" scoped></style>
