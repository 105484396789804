<template>
  <span>
    {{ value }}
  </span>
</template>

<script>
import CellAbstract from "../CellAbstract.vue";
export default {
  extends: CellAbstract,
};
</script>

<style lang="scss" scoped></style>
