<template>
  <v-layout align-center>
    <v-flex v-if="editing" style="flex-basis: 90%">
      <v-textarea
        hide-details
        solo
        flat
        rows="2"
        v-model="textAreaValue"
      ></v-textarea>
    </v-flex>
    <v-flex v-else style="flex-basis: 90%">
      <div>{{ value }}</div>
    </v-flex>
    <v-flex v-if="editing">
      <v-btn color="green" icon x-small @click="save"
        ><v-icon>mdi-check</v-icon></v-btn
      >
    </v-flex>
    <v-flex v-else grow="1">
      <v-btn icon x-small @click="editing = true"
        ><v-icon>mdi-pencil</v-icon></v-btn
      >
    </v-flex>
  </v-layout>
</template>

<script>
import CellAbstract from "../CellAbstract.vue";
export default {
  extends: CellAbstract,
  data() {
    return {
      editing: false,
      textAreaValue: "",
    };
  },
  methods: {
    save() {
      this.editing = false;
      this.$emit("onChange", this.uuid, this.textAreaValue);
    },
  },
  created() {
    this.textAreaValue = this.value;
  },
};
</script>

<style lang="scss" scoped></style>
