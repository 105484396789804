<template>
  <div class="table-view__cell" :style="customStyles">
    <component
      @onChange="onChange"
      :is="type"
      :uuid="data.uuid"
      v-bind="data"
      :value="value"
    ></component>
  </div>
</template>

<script>
import Text from "./CellComponents/Text";
import TextField from "./CellComponents/TextField";
export default {
  props: ["config", "data", "slug", "maxWidth"],
  components: {
    "text-cell": Text,
    TextField,
  },
  data() {
    return {
      type: null,
    };
  },
  computed: {
    value() {
      let val = this.config.get
        ? this.config.get(this.data)
        : this.data[this.slug];
      return val || "-";
    },
    customStyles() {
      return {
        "flex-basis": this.maxWidth ? this.maxWidth : "1",
        "flex-grow": this.maxWidth ? "0" : "1",
      };
    },
  },
  methods: {
    initialize() {
      let type = this.config.type;

      switch (type) {
        case "text": {
          this.type = "text-cell";
          break;
        }
        case "text-field": {
          this.type = "text-field";
          break;
        }
        default: {
          this.type = "text-cell";
          break;
        }
      }
    },
    onChange(uuid, val) {
      this.$emit("onChange", uuid, this.slug, val);
    },
  },
  beforeMount() {
    this.initialize();
  },
};
</script>

<style lang="scss" scoped></style>
